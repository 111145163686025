<template>
    <div class="row">
        <div class="container container--out-pad container--add-pad">
            <div class="education__request-title">оформление заявки</div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-clg-2">
                    <div class="education__request-item">
                        Программа
                        <div class="education__request-item-required">*</div>
                    </div>
                    <ValidationObserver slim v-slot="{ invalid, handleSubmit }">
                        <form @submit.prevent="handleSubmit(postRequest)">
                            <div v-if="courses.length === 1" class="education__request-item-title">
                                {{ courses[0].title }}
                            </div>
                            <div v-else>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <select
                                        v-model="selectedCourse"
                                        class="form-control"
                                        @change="updateSelectedCourse"
                                    >
                                        <option value="" disabled>Выберите курс</option>
                                        <option
                                            v-for="course in courses"
                                            :key="course.id"
                                            :value="course.id"
                                        >
                                            {{ course.title }}
                                        </option>
                                    </select>
                                    <span class="error-message" v-if="errors[0]">
                                        <br />**{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div v-if="$route.params.id === 'technical-28'">
                                <div class="education__request-form">
                                    <div class="education__request-item">
                                        Формат обучения
                                        <div class="education__request-item-required">*</div>
                                    </div>
                                    <ValidationProvider
                                        :name="format_id"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <select
                                            v-model="format_id"
                                            class="form-control"
                                            v-if="page.coursePrice.length > 1"
                                            @change="
                                                () => {
                                                    city = '';
                                                    start_at = '';
                                                }
                                            "
                                        >
                                            <option
                                                v-for="(item, id) in page.coursePrice"
                                                :key="id"
                                                :value="item.format_name"
                                            >
                                                {{ item.format_title }}
                                            </option>
                                        </select>
                                        <input
                                            v-else
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                currentCourse.coursePrice[0].format_title ||
                                                page.coursePrice[0].format_title
                                            "
                                        />
                                        <span class="error-message" v-if="errors[0]">
                                            <br />
                                            **{{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div
                                class="education__request-form"
                                v-show="
                                    (courses.length > 1 && selectedCourse) || courses.length < 2
                                "
                            >
                                <div class="education__request-item">
                                    Дата начала обучения
                                    <div class="education__request-item-required">*</div>
                                </div>
                                <ValidationProvider :name="start_at" rules="required">
                                    <input
                                        placeholder="Выберите дату"
                                        v-model="start_at"
                                        class="form-control date"
                                        type="text"
                                        id="date"
                                        autocomplete="off"
                                        @blur="getDate"
                                        readonly
                                        style="background-color: #fff"
                                        :disabled="
                                            course_id === '9CDB261F-A3FA-6BE8-30C5-160E2EAEFA9B' &&
                                            format_id === ''
                                        "
                                    />
                                </ValidationProvider>
                                <span class="popup" v-if="showPopup && ticketCount !== null">
                                    Осталось мест {{ ticketCount }}</span
                                >
                            </div>

                            <div class="education__request-item-desc">
                                Информация о кураторе группы со стороны заказчика
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    ФИО куратора
                                    <div class="education__request-item-required">*</div>
                                </div>
                                <ValidationProvider
                                    :name="curator_name"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        v-model="curator_name"
                                        class="form-control"
                                        type="text"
                                        maxlength="35"
                                    />
                                    <span class="error-message" v-if="errors[0]">
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    Номер телефона куратора
                                    <div class="education__request-item-required">*</div>
                                </div>
                                <ValidationProvider
                                    :name="curator_phone"
                                    rules="phone: 18"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        v-model="curator_phone"
                                        v-mask="'+7-(###)-###-##-##'"
                                        class="form-control"
                                        type="text"
                                    />
                                    <span class="error-message" v-if="errors[0]">
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    E-mail куратора
                                    <div class="education__request-item-required">*</div>
                                </div>
                                <ValidationProvider
                                    :name="curator_email"
                                    rules="email"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        v-model="curator_email"
                                        class="form-control"
                                        type="text"
                                    />
                                    <span class="error-message" v-if="errors[0]">
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div
                                v-if="
                                    $route.params.id !== 'technical-28' &&
                                    page.url !== 'marketing-coaching-1'
                                "
                                class="education__request-form"
                            >
                                <div class="education__request-item">
                                    Формат обучения
                                    <div class="education__request-item-required">*</div>
                                </div>
                                <ValidationProvider
                                    :name="format_id"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <select
                                        v-model="format_id"
                                        class="form-control"
                                        v-if="page.coursePrice.length > 1"
                                        @change="
                                            () => {
                                                city = '';
                                                start_at = '';
                                            }
                                        "
                                    >
                                        <option
                                            v-for="(item, id) in page.coursePrice"
                                            :key="id"
                                            :value="item.format_name"
                                        >
                                            {{ item.format_title }}
                                        </option>
                                    </select>
                                    <input
                                        v-else
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="page.coursePrice[0].format_title"
                                    />
                                    <span class="error-message" v-if="errors[0]">
                                        <br />
                                        **{{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                            <div class="education__request-form">
                                <div class="education__request-item">
                                    Количество участников
                                    <div class="education__request-item-required">*</div>
                                </div>
                                <div class="education__request-people">
                                    <div
                                        v-if="page.url !== 'marketing-coaching-1'"
                                        class="education__request-people-count"
                                        @click="
                                            user_count > 1 && user_count > Number(page.min_user_count)
                                                ? user_count--
                                                : user_count
                                        "
                                    >
                                        -
                                    </div>
                                    <div
                                        v-else
                                        class="education__request-people-count"
                                        @click="
                                            user_count > 1 && user_count > Number(minUserCount)
                                                ? user_count--
                                                : user_count
                                        "
                                    >
                                        -
                                    </div>
                                    <div>
                                        <input
                                            class="education__request-people-count"
                                            v-model="user_count"
                                            readonly
                                        />
                                    </div>
                                    <div
                                        v-if="
                                            page.url !== 'education-tp-1' &&
                                            page.url !== 'marketing-coaching-1'
                                        "
                                        class="education__request-people-count"
                                        @click="
                                            user_count < maxUserCount ? user_count++ : user_count
                                        "
                                    >
                                        +
                                    </div>
                                    <div
                                        v-else
                                        class="education__request-people-count"
                                        @click="user_count++"
                                    >
                                        +
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        user_count >= maxUserCount &&
                                        page.url !== 'education-tp-1' &&
                                        page.url !== 'marketing-coaching-1'
                                    "
                                    class="warning-message"
                                    style="color: #d2233c"
                                >
                                    Достигнуто максимальное количество участников.
                                </div>
                                <transition name="fade" v-if="courses.length > 1">
                                    <div class="education__request-people-count-desc">
                                        Обучение проводится только в случае набора группы – <br />
                                        минимальное количество участников 20 человек
                                    </div>
                                </transition>
                                <transition
                                    name="fade"
                                    v-else-if="!page.max_user_count && !includesFormatType()"
                                >
                                    <div
                                        class="education__request-people-count-desc"
                                        v-if="
                                            user_count <= 10 &&
                                            page.url !== 'education-tp-1' &&
                                            page.url !== 'marketing-coaching-1'
                                        "
                                    >
                                        В случае, если количество участников менее 10, будет
                                        организовываться сборный тренинг с участниками из других
                                        компаний
                                    </div>
                                </transition>
                            </div>
                            <transition name="fade">
                                <div
                                    class="education__request-form"
                                    v-if="
                                        format_id.indexOf('offline') !== -1 &&
                                        page.url !== 'education-tp-1' &&
                                        page.url !== 'marketing-coaching-1' &&
                                        courses.length < 2
                                    "
                                >
                                    <div class="education__request-item">
                                        {{ formatCityField(format_id) }}
                                        <div class="education__request-item-required">*</div>
                                    </div>
                                    <ValidationProvider
                                        :name="city"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <input
                                            v-model="city"
                                            class="form-control"
                                            type="text"
                                            maxlength="35"
                                        />
                                        <span class="error-message" v-if="errors[0]">
                                            <br />
                                            **{{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                    <div class="education__request-people-count-desc">
                                        Командировочные расходы на перелет и проживание тренера
                                        оплачиваются дополнительно.
                                    </div>
                                </div>
                            </transition>
                            <div class="education__request-sum">
                                <transition name="fade">
                                    <div class="education__request-price" v-if="sum != 0">
                                        Итого:
                                        {{ reSum }}
                                        баллов
                                    </div>
                                </transition>
                                <div class="education__request-price">
                                    Баланс:
                                    {{ reBalance }}
                                    баллов
                                </div>
                                <div class="education__request-price-error" v-if="error_balance < 0">
                                    Не хватает:
                                    {{ reError_balance }} баллов
                                </div>
                                <div class="education__button">
                                    <button
                                        :class="
                                            invalid ||
                                            error_balance <= 0 ||
                                            curator_email === '' ||
                                            disabled
                                                ? 'education__button-btn disabled'
                                                : 'education__button-btn'
                                        "
                                        type="submit"
                                    >
                                        ОФОРМИТЬ ЗАЯВКУ
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-clg-1">
                    <div class="education__detail-description-list-title">
                        Для кого тренинг?
                    </div>
                    <div class="education__detail-description-list">
                        <div class="education__detail-description-text">
                            {{ page.audience }}
                        </div>
                    </div>
                    <div class="education__detail-description-list-title">
                        Продолжительность
                    </div>
                    <div class="education__detail-description-list">
                        <div
                            class="education__detail-description-text"
                            v-if="courses.length > 1"
                            v-html="timeCourse"
                        ></div>
                        <div class="education__detail-description-text" v-else>
                            {{ timeCourse }}
                        </div>
                    </div>
                    <div v-if="courses.length > 1 && selectedCourse">
                        <div class="education__detail-description-list-title">
                            Формат обучения
                        </div>
                        <div class="education__detail-description">
                            <ul
                                class="education__detail-description-ul"
                                v-for="(item, id) in currentCourse.coursePrice"
                                :key="id"
                            >
                                <li class="education__detail-description-ul-item">
                                    {{ item.format_title }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else>
                        <div class="education__detail-description-list-title">
                            Формат обучения
                        </div>
                        <div class="education__detail-description">
                            <ul
                                class="education__detail-description-ul"
                                v-for="(item, id) in page.coursePrice"
                                :key="id"
                            >
                                <li class="education__detail-description-ul-item">
                                    {{ item.format_title }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        data-v-343cc5f0=""
                        class="education__detail-description-list-title"
                    >
                        Стоимость обучения*
                    </div>
                    <div data-v-343cc5f0="" class="education__detail-description">
                        <div data-v-343cc5f0="" class="education__detail-table">
                            <div data-v-343cc5f0="" class="education__detail-table-item">
                                Формат обучения
                            </div>
                            <div data-v-343cc5f0="" class="education__detail-table-item">
                                {{
                                    this.page.is_full_price === "1"
                                        ? "Стоимость за услугу* (руб.)"
                                        : "Стоимость за участника* (руб.)"
                                }}
                            </div>
                        </div>
                        <div v-if="courses.length > 1 && selectedCourse">
                            <div
                                class="education__detail-table"
                                v-for="(item, id) in currentCourse.coursePrice"
                                :key="id"
                            >
                                <div class="education__detail-table-item">
                                    {{ item.format_title }}
                                </div>
                                <div class="education__detail-table-item">
                                    {{
                                        String(item.price).replace(
                                            /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                                            "$1 "
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div
                                class="education__detail-table"
                                v-for="(item, id) in page.coursePrice"
                                :key="id"
                            >
                                <div class="education__detail-table-item">
                                    {{ item.format_title }}
                                </div>
                                <div class="education__detail-table-item">
                                    {{
                                        String(item.price).replace(
                                            /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                                            "$1 "
                                        )
                                    }}
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="
                                !includesFormatType() &&
                                courses.length < 2 &&
                                page.url !== 'education-tp-1' &&
                                page.url !== 'marketing-coaching-1'
                            "
                        >
                            <div class="education__detail-description-list-title">
                                Размер группы (кол-во человек в одной группе)
                            </div>
                            <div
                                class="education__detail-description"
                                v-if="page.max_user_count"
                            >
                                <div class="education__detail-description-text">
                                    Максимальное количество участников для проведения обучения –
                                    {{ page.max_user_count }} человек.
                                </div>
                            </div>
                            <div class="education__detail-description" v-else>
                                <div class="education__detail-description-text">
                                    Минимальное количество участников для проведения обучения – 10
                                    человек.
                                </div>
                                <div class="education__detail-description-text">
                                    В случае, если количество участников менее 10, формируется
                                    сборная группа из нескольких компаний-дилеров
                                </div>
                            </div>
                        </div>
                        <div
                            v-else-if="
                                (!includesFormatType() &&
                                courses.length > 1 &&
                                currentCourse.min_user_count) ||
                                currentCourse.max_user_count
                            "
                        >
                            <div class="education__detail-description-list-title">
                                Размер группы (кол-во человек в одной группе)
                            </div>
                            <div class="education__detail-description">
                                <div class="education__detail-description-text">
                                    Для проведения обучения формируется сборная группа из
                                    нескольких компаний-дилеров
                                    <br />
                                    Минимальное количество участников для проведения обучения –
                                    {{ currentCourse.min_user_count }}
                                    человек.
                                    <br />
                                    Максимальное количество – {{ currentCourse.max_user_count }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="education__detail-footer-description"
                            v-if="
                                currentCourse.additional_description ||
                                page.additional_description
                            "
                        >
                            <div class="education__detail-footer-description-title">
                                Дополнительно
                            </div>
                            <div
                                class="education__detail-footer-description-text"
                                v-html="
                                    currentCourse.additional_description ||
                                    page.additional_description
                                "
                            ></div>
                        </div>
                        <div class="education__detail-footer-description" v-else>
                            <div class="education__detail-footer-description-title">
                                Дополнительно
                            </div>
                            <div class="education__detail-footer-description-text">
                                *Стоимость указана без учёта НДС. Компания Digital Concept НДС
                                не облагается, в&nbsp;связи с&nbsp;применением упрощённой
                                системы налогообложения.
                                <p>
                                    **Дополнительные расходы, связанные с&nbsp;перелетом и
                                    проживанием участников, оплачиваются дополнительно (при
                                    проведении очного сборного тренинга). Дополнительные расходы
                                    по&nbsp;организации очного обучения (аренда аудитории
                                    и&nbsp;оборудования, питание для участников) оплачиваются
                                    дополнительно. Дополнительные расходы, связанные
                                    с&nbsp;командировочными затратами на&nbsp;перелет
                                    и&nbsp;проживание тренера оплачиваются дополнительно (при
                                    проведении очного тренинга в&nbsp;городе Заказчика,
                                    за&nbsp;исключением Санкт-Петербурга).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Datepicker } from "vanillajs-datepicker";
import ru from "vanillajs-datepicker/js/i18n/locales/ru.js";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { get, post } from "@/lib/axios";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            page: "",
            courses: [], // Массив курсов
            selectedCourse: "", // ID выбранного курса
            currentCourse: {}, // Объект выбранного курса
            datePicker: null,
            user_count: 10,
            maxUserCount: 20,
            start_at: "",
            curator_name: "",
            curator_phone: "",
            curator_email: "",
            city: "",
            course_id: "",
            format_id: "",
            disabled: false,
            dates: [],
            substitutionArray: [
                "Комплексное решение по повышению безопасности бизнеса в области потребительского экстремизма",
                "Аудит бизнес-процессов автосервиса",
                "Открытие поста аппаратной замены масла в АКПП: оборудование, технологии, обучение, техподдержка",
            ],
            ticketCount: null,
            showPopup: false,
            datesDisabled: [],
            minUserCount: 10,
        };
    },
    methods: {
        async fetchAvailableDates() {
            // Проверка на количество курсов
            if (this.courses.length < 2) {
                this.dates = []; // или любые другие действия при недостаточном количестве курсов
                return; // Прерываем выполнение, если количество курсов < 2
            }

            // Получаем доступные даты для текущего курса
            const courseId = this.selectedCourse; // или this.currentCourse.id
            try {
                const response = await get(`/education/dates/${courseId}`);
                this.dates = response.data; // Предполагаем, что данные представлены в нужном формате
                this.updateDatePicker(); // Обновляем datePicker после получения новых
            } catch (error) {
                console.error("Ошибка при получении дат:", error);
            }
        },

        updateDatePicker() {
            const dateElement = document.getElementById("date");
            // Получаем недоступные даты (все даты, кроме полученных из API)
            const disabledDates = this.getDatesDisabled(this.dates);

            const options = {
                format: "yyyy/mm/dd",
                language: "ru",
                minDate: new Date(),
                maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 3)),
                datesDisabled: disabledDates, // Убедитесь, что это именно недоступные даты
            };

            // Если у нас есть предыдущий datePicker, надо его уничтожить
            if (this.datePicker) {
                this.datePicker.destroy();
            }

            // Создаем новый экземпляр Datepicker
            this.datePicker = new Datepicker(dateElement, options);
        },

        async checkUserCount(formattedDate, event) {
            // if (!this.start_at || !this.selectedCourse) {
            //   console.log("Нужно выбрать дату и курс");
            //   return; // Не нужно проверять, если дата или курс не выбраны
            // }

            try {
                const response = await get(
                    `/education/get-user-count?course_id=${this.selectedCourse}&date=${formattedDate}`
                );
                const userCount = response.data;
                this.ticketCount = userCount;
                this.showPopup = true;

                console.log(
                    `/education/get-user-count?course_id=${this.selectedCourse}&date=${formattedDate}`
                );
            } catch (error) {
                console.error("Ошибка при получении количества мест:", error);
            }
        },

        includesFormatType() {
            if (this.substitutionArray.includes(this.page.title)) {
                return true;
            }
            return false;
        },
        getDatesDisabled(selectedDates) {
            const startDate = new Date("2022-01-01");
            const endDate = new Date("2025-12-31");
            const dates = [];
            for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                dates.push(new Date(d));
            }
            const selected = selectedDates.map((date) => new Date(date));
            const unselected = dates.filter(
                (date) =>
                    !selected.some(
                        (selectedDate) => selectedDate.getTime() === date.getTime()
                    )
            );
            if (this.courses.length > 1) {
                const allDates = this.getAllPossibleDates(); // функция, возвращающая все возможные даты выбора
                const availableDates = new Set(
                    selectedDates.map(
                        (date) => new Date(date).toISOString().split("T")[0]
                    )
                );

                // Возвращаем все даты, которые не являются доступными
                return allDates.filter((date) => !availableDates.has(date));
            }
        },
        getAllPossibleDates() {
            // Вернем массив строк в формате 'yyyy-mm-dd' для всех возможных дат (например, за 3 года)
            const dates = [];
            const startDate = new Date();
            const endDate = new Date(
                new Date().setFullYear(startDate.getFullYear() + 3)
            );

            for (
                let dt = new Date(startDate);
                dt <= endDate;
                dt.setDate(dt.getDate() + 1)
            ) {
                dates.push(dt.toISOString().split("T")[0]);
            }

            return dates;
        },

        async getPeopleCount(event) {
            const date = new Date(Number(event.dataset.date)).toLocaleDateString(
                "RU-ru"
            );
            const userCount = await get(
                `education/people/${this.page.id}?date=${date}`
            ).then((response) => {
                return +response.data.count;
            });

            if (userCount >= this.page.max_user_count) {
                event.setAttribute("data-tooltip-education", `Доступных мест нет`);
                this.maxUserCount = 1;
                this.user_count = 1;
                this.datePicker.dates = [];
                const element = document.querySelector("#date");
                element.value = "";
                return;
            }
            event.setAttribute(
                "data-tooltip-education",
                `Доступно ${
                    this.page.max_user_count
                        ? this.page.max_user_count - userCount
                        : 20 - userCount
                } мест`
            );
            this.maxUserCount = this.page.max_user_count
                ? this.page.max_user_count - userCount
                : 20 - userCount;
            this.user_count = 1;

            this.start_at = this.getFormattedDate(this.datePicker.dates[0]);
        },

        async fetchData(courseId) {
            await get(`/education/course/${this.$route.params.id}`)
                .then((response) => {
                    this.page = response.data[0];
                    this.courses = response.data; // Получаем курсы
                    this.maxUserCount = this.page.max_user_count || 20;
                    this.minUserCount = this.page.min_user_count || 10;

                    if (
                        this.page.url === "education-tp-1" ||
                        this.page.url === "marketing-coaching-1"
                    ) {
                        this.user_count = 1;
                        this.minUserCount = 1;
                    } else if (this.page.min_user_count !== null) {
                        this.user_count = Number(this.page.min_user_count);
                    } else {
                        this.user_count = 10;
                    }
                    if (this.courses.length === 1) {
                        this.selectedCourse = this.courses[0].id;
                        this.currentCourse = this.courses[0]; // Сохраняем текущий курс
                    }
                })
                .catch((e) => {
                    console.log(e);
                });

            const getDates = await get(
                `education/dates/${courseId || this.page.id}`
            ).then((response) => {
                console.log(response.data);
                return response.data;
            });
            if (this.substitutionArray.includes(this.page.title)) {
                this.user_count = 1;
            }
            this.dates = getDates;
            if (this.page.coursePrice.length === 1) {
                this.format_id = this.page.coursePrice[0].format_name;
            }
            this.course_id = this.page.id;
            Object.assign(Datepicker.locales, ru);
            const date = document.getElementById("date");
            const D = new Date();
            const Dy = new Date();
            Dy.setFullYear(Dy.getFullYear() + 3);
            if (getDates.length > 0) {
                const dates = this.getDatesDisabled(getDates);
                this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    language: "ru",
                    defaultViewDate: D,
                    datesDisabled: dates,
                    minDate: D,
                    maxDate: Dy,
                });
                const state = this;
                const cell = document.querySelectorAll(".datepicker-cell.day");
                cell.forEach((item) => {
                    if (!item.classList.contains("disabled")) {
                        item.addEventListener("click", function (event) {
                            state.getPeopleCount(event.target);
                        });
                    }
                });
                const next = document.querySelector(".next-btn");
                next.addEventListener("click", function () {
                    cell.forEach((item) => {
                        item.removeAttribute("data-tooltip-education");
                    });
                });
                const prev = document.querySelector(".prev-btn");
                prev.addEventListener("click", function () {
                    cell.forEach((item) => {
                        item.removeAttribute("data-tooltip-education");
                    });
                });
                return;
            }

            if (this.substitutionArray.includes(this.page.title)) {
                this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    language: "ru",
                    defaultViewDate: D,
                    minDate: D,
                    maxDate: Dy,
                });
                date.addEventListener("changeDate", function (e) {
                    const a = document.getElementsByClassName("datepicker-cell");
                    for (let i = 0; i < a.length; i++) {
                        if (a[i].className === "datepicker-cell day selected focused") {
                            a[i + 1].classList.add("focused");
                        }
                    }
                });
                return;
            }

            if (this.page.courseType.name === "technical") {
                D.setMonth(D.getMonth() + 3);
            } else {
                D.setDate(D.getDate() + 14);
            }

            this.page.courseType.name === "technical"
                ? (this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    daysOfWeekDisabled: [0, 4, 5, 6],
                    language: "ru",
                    defaultViewDate: D,
                    minDate: D,
                    maxDate: Dy,
                }))
                : (this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    language: "ru",
                    defaultViewDate: D,
                    minDate: D,
                    maxDate: Dy,
                }));
            this.page.hours === "24"
                ? date.addEventListener("changeDate", function (e) {
                    const a = document.getElementsByClassName("datepicker-cell");
                    for (let i = 0; i < a.length; i++) {
                        if (a[i].className === "datepicker-cell day selected focused") {
                            a[i + 1].classList.add("focused");
                            a[i + 2].classList.add("focused");
                        }
                    }
                })
                : date.addEventListener("changeDate", function (e) {
                    const a = document.getElementsByClassName("datepicker-cell");
                    for (let i = 0; i < a.length; i++) {
                        if (a[i].className === "datepicker-cell day selected focused") {
                            a[i + 1].classList.add("focused");
                        }
                    }
                });
        },
        updateSelectedCourse() {
            // Находим выбранный курс по ID
            const selected = this.courses.find(
                (course) => course.id === this.selectedCourse
            );

            if (selected) {
                this.currentCourse = selected;
                console.log(this.currentCourse);
                // Здесь можно добавить код для обновления других связанных данных, если нужно
            } else {
                console.error("Курс не найден!");
                this.currentCourse = {};
            }
        },

        getFormattedDate(value) {
            let date = new Date(value);

            let month = date.getMonth() + 1;
            let day = date.getDate();
            const fullYear = date.getFullYear();

            month = (month < 10 ? "0" : "") + month;
            day = (day < 10 ? "0" : "") + day;
            if (this.datePicker.dates[0]) {
                return `${fullYear}/${month}/${day}`;
            }
        },
        getDate() {
            this.start_at = this.getFormattedDate(this.datePicker.dates[0]);
            this.checkUserCount(this.start_at);
        },

        formatCityField(type) {
            if (type) {
                const course = this.page.coursePrice.find(
                    ({ format_name }) => format_name === type
                );

                if (
                    course.format_title ===
                    "Офлайн, на учебно-практической площадке ИЦ СМАРТ в г. Москве" ||
                    course.format_title ===
                    "Офлайн, на учебно-практической площадке ИЦ СМАРТ в г. Москве + онлайн"
                ) {
                    this.city = "г. Москва";
                }

                if (this.substitutionArray.includes(this.page.title)) {
                    return "Адрес автосервиса (место проведения обучения)";
                }
            }
            return "Город проведения";
        },
        postRequest() {
            this.disabled = true;
            const {
                curator_name,
                curator_phone,
                curator_email,
                city,
                user_count,
                start_at,
                course_id,
            } = this;
            const format_id =
                this.page.coursePrice.length > 1
                    ? this.page.coursePrice.find(
                        ({ format_name }) => format_name === this.format_id
                    ).id
                    : this.page.coursePrice[0].id;
            const data = {
                curator_name,
                curator_phone,
                curator_email,
                city,
                user_count,
                start_at,
                course_id: this.selectedCourse,
                format_id,
            };
            if (this.error_balance > 0) {
                post("education/course/order", data)
                    .then(() => {
                        this.$router.replace({ name: "SuccessfulOrder" });
                        this.disabled = false;
                    })
                    .catch((e) => {
                        console.log(data);
                        this.disabled = false;
                        console.log(e);
                    });
            }
        },
    },
    beforeMount() {
        this.fetchData();
    },
    mounted() {
        // Изначально загружаем доступные даты
        this.courses.length > 1 &&
        this.fetchAvailableDates().then(() => {
            this.updateDatePicker(); // Инициализируем календарь
        });
        this.courses.length > 1 || this.page.url === "education-tp-1"
            ? (this.user_count = 1)
            : (this.user_count = 10);
    },
    watch: {
        currentCourse(newCourse) {
            this.courses.length > 1 &&
            this.fetchAvailableDates().then(() => {
                this.updateDatePicker(); // Обновляем календарь
            });
        },
        selectedCourse() {
            this.updateSelectedCourse(); // Обновляем текущий курс
        },
        user_count(newCount) {
            if (
                newCount > this.maxUserCount &&
                this.page.url !== "education-tp-1" &&
                this.page.url !== "marketing-coaching-1"
            ) {
                this.user_count = Number(this.maxUserCount);
            }
        },
        curator_name() {
            if (this.curator_name.charAt(0) === " ") {
                this.curator_name = "";
            }
        },
        curator_email() {
            const reg = /[а-яА-ЯёЁ\s]/g;
            if (this.curator_email.search(reg) != -1) {
                this.curator_email = this.curator_email.replace(reg, "");
            }
        },
        city() {
            if (this.city.charAt(0) === " ") {
                this.city = "";
            }
        },
        format_id(newFormat) {
            const date = document.getElementById("date");
            const D = new Date();
            const Dy = new Date();
            Dy.setFullYear(Dy.getFullYear() + 3);

            if (
                newFormat === "offline1" &&
                this.page.title ===
                "Технологии обслуживания клиентов технических центров"
            ) {
                this.datePicker.destroy();
                this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    language: "ru",
                    defaultViewDate: D,
                    minDate: D,
                    maxDate: Dy,
                });
                date.addEventListener("changeDate", function (e) {
                    const a = document.getElementsByClassName("datepicker-cell");
                    for (let i = 0; i < a.length; i++) {
                        if (a[i].className === "datepicker-cell day selected focused") {
                            a[i + 1].classList.add("focused");
                        }
                    }
                });
            } else if (
                this.page.title ===
                "Технологии обслуживания клиентов технических центров"
            ) {
                this.datePicker.destroy();
                const dates = this.getDatesDisabled(this.dates);
                this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    language: "ru",
                    defaultViewDate: D,
                    datesDisabled: dates,
                    minDate: D,
                    maxDate: Dy,
                });
                const state = this;
                const cell = document.querySelectorAll(".datepicker-cell");
                cell.forEach((item) => {
                    item.addEventListener("click", function (event) {
                        state.getPeopleCount(event.target);
                    });
                });
            } else if (
                this.page.title === "Основы экономики и бизнес-процессы автосервиса"
            ) {
                this.datePicker.destroy();
                const datesEnabled = ["2024/03/27"];
                const dates = this.getDatesDisabled(this.dates).filter(
                    (date) => !datesEnabled.includes(date)
                );
                this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    language: "ru",
                    defaultViewDate: D,
                    datesDisabled: dates,
                    minDate: D,
                    maxDate: Dy,
                });
                const state = this;
                const cell = document.querySelectorAll(".datepicker-cell");
                cell.forEach((item) => {
                    item.addEventListener("click", function (event) {
                        state.getPeopleCount(event.target);
                    });
                });
            } else if (
                this.page.title ===
                "Диагностика и обслуживание электронных систем автомобилей"
            ) {
                this.datePicker.destroy();
                const datesEnabled = [
                    "2024/04/01",
                    "2024/04/29",
                    "2024/05/27",
                    "2024/06/24",
                    "2024/07/22",
                    "2024/08/19",
                    "2024/09/16",
                    "2024/10/14",
                    "2024/11/18",
                    "2024/12/16",
                ];
                const dates = this.getDatesDisabled(this.dates).filter(
                    (date) => !datesEnabled.includes(date)
                );
                this.datePicker = new Datepicker(date, {
                    format: "yyyy/mm/dd",
                    language: "ru",
                    defaultViewDate: D,
                    datesDisabled: dates,
                    minDate: D,
                    maxDate: Dy,
                });
                const state = this;
                const cell = document.querySelectorAll(".datepicker-cell");
                cell.forEach((item) => {
                    item.addEventListener("click", function (event) {
                        state.getPeopleCount(event.target);
                    });
                });
            }
        },
    },
    computed: {
        ...mapGetters("account", ["accountInfo", "pointsNumber"]),
        sum() {
            const selectItem = this.page.coursePrice.find(
                ({ format_name }) => format_name === this.format_id
            );
            if (selectItem?.price && this.page.is_full_price) {
                return selectItem.price;
            }
            if (selectItem?.price) {
                return this.user_count * selectItem.price;
            }
            return 0;
        },
        timeCourse() {
            if (this.courses.length > 1) {
                return this.courses
                    .map((course, index) => {
                        // Генерируем римские цифры для модулей
                        const romanNumerals = [
                            "I",
                            "II",
                            "III",
                            "IV",
                            "V",
                            "VI",
                            "VII",
                            "VIII",
                            "IX",
                            "X",
                        ];
                        const moduleName = romanNumerals[index] || (index + 1).toString(); // На случай больше 10 модулей
                        return `Модуль ${moduleName} – ${course.hours} часов`;
                    })
                    .join("<br/>");
            }
            if (this.page.course_duration) {
                return this.page.course_duration;
            }
            return this.currentCourse.hours === "16" || this.page.hours === "16"
                ? `${this.currentCourse.hours || this.page.hours} часов (2 дня) `
                : `${this.currentCourse.hours || this.page.hours} часа (3 дня)`;
        },
        reError_balance() {
            const error = (this.pointsNumber - this.sum) * -1;
            return String(error).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
        },
        reSum() {
            const newSumma = String(this.sum).replace(
                /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                "$1 "
            );
            return newSumma;
        },
        reBalance() {
            const newBalance = String(this.pointsNumber).replace(
                /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                "$1 "
            );
            return newBalance;
        },
        error_balance() {
            return this.pointsNumber - this.sum;
        },
    },
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    metaInfo() {
        return {
            title: `Оформление заявки - ЛУКМАРКЕТ`,
        };
    },
};
</script>

<style lang="sass">
.error-message
    color: #D2233C

.fade-enter-active, .fade-leave-active
    transition: opacity .3s

.fade-enter, .fade-leave-to
    opacity: 0


.education__request
    &-title
        font-size: 24px
        line-height: 25px
        text-transform: uppercase
        font-weight: 700
        padding: 60px 0 20px 0

    &-price
        font-weight: 700
        font-size: 24px
        line-height: 25px
        margin: 20px 0

        &-error
            font-size: 16px
            font-weight: 400
            color: #D2233C
            margin: 0 0 27px 0

    &-sum
        margin: 40px 0

    &-people
        display: flex
        width: calc(36px * 4)
        justify-content: space-between

        &-count
            border: 1px solid #E9E9E9
            padding: 8px
            font-size: 18px
            line-height: 19px
            width: 100%
            text-align: center
            font-weight: 400
            cursor: pointer
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
            flex: 0 1 auto

            &:hover
                border-color: #80bdff

            &:nth-child(2)
                cursor: default
                flex: 1 0 auto

                &:hover
                    border: 1px solid #E9E9E9

            &-desc
                color: #D2233C
                font-size: 16px
                font-weight: 400
                line-height: 17px
                margin: 20px 0


    &-item
        display: flex
        flex-direction: row
        font-size: 18px
        line-height: 19px
        font-weight: 400
        margin: 20px 0


        &-desc
            margin: 40px 0
            font-size: 18px
            line-height: 19px

        &-required
            color: #D2233C

        &-title
            background-color: #FAFAFA
            color: #000000
            font-size: 18px
            font-weight: 400
            line-height: 19px
            width: 100%
            padding: 13px

        &-select
            background-color: #FAFAFA
            color: #000000
            font-size: 18px
            font-weight: 400
            line-height: 19px
            width: 100%
            padding: 13px
            outline: none
            border: none

.date
    width: 262px

.row
    margin: 0

.education__detail
    &-footer-description
        padding: 20px
        margin: 40px 0
        background: #F7F7F9

        &-title
            color: #D2233C
            font-size: 14px
            font-weight: 400
            line-height: 14.74px

        &-text
            font-weight: 400
            font-size: 12px
            line-height: 12.64px
            padding-top: 12px

    &-description
        color: #4A4A5A
        font-size: 16px
        line-height: 17px
        margin: 20px 0

        &-text
            font-size: 18px
            font-weight: 400
            line-height: 19px
            color: #000000
            margin: 20px 0
            max-width: 511px
            width: 100%

        &-ul
            margin: 20px -20px

            &-item
                font-size: 18px
                font-weight: 400
                line-height: 19px
                color: #000
                margin: 12px 0

        &-list
            display: flex
            flex-direction: row
            align-items: center

            &-title
                margin: 20px 0 0px 0
                font-weight: 700
                color: #D2233C
                font-size: 18px
                line-height: 19px

            &-text
                padding: 12px 30px
                color: #000
                font-weight: 400
                font-size: 18px
                line-height: 19px

.education__button
    display: block
    text-align: left

    &-btn
        min-width: 275px
        text-align: center
        display: inline-block
        background: #d2233c
        border-radius: 4px
        color: #fff !important
        text-transform: uppercase
        font: 700 16px/24px Futuris
        margin-top: 20px
        padding: 10px
        text-decoration: none
        cursor: pointer

        &.disabled
            background-color: #777
            border-color: #777
            cursor: not-allowed

.education__detail-table
    color: #000000
    background-color: #EFEFEF
    max-width: 544px
    width: 100%
    display: flex
    padding: 8px 12px 8px 8px
    border: 1px solid #fff

    &-item
        width: 50%
        padding: 0 5px

    &:nth-child(2)
        background: #F4F4F4


[data-tooltip-education]
    position: relative

[data-tooltip-education]::before
    content: attr(data-tooltip-education)
    position: absolute
    width: 130px
    height: 33px
    font-size: 12px
    line-height: 12px
    left: 0px
    bottom: 35px
    background: #F7F7F9
    color: #000000
    padding: 10px
    pointer-events: none
    opacity: 0
    transition: 0.3s ease-in-out

[data-tooltip-education]:hover::after
    content: " "
    position: absolute
    bottom: 110%
    left: 50%
    margin-left: -5px
    width: 12px
    height: 12px
    border-top: 10px solid #F7F7F9
    border-right: 6px solid transparent
    border-left: 6px solid transparent
    font-size: 0
    line-height: 0


[data-tooltip-education]:hover::before
    opacity: 1
    bottom: 50px


.education__request-form:has(.popup)
    display: flex
    flex-direction: row
    flex-wrap: wrap

    .education__request-item
        flex: 1 0 100%

.popup
    background: transparent
    color: #000
    padding: 10px
    border-radius: 5px
    flex: 0 1 auto

.datepicker-dropdown
    z-index: 999
</style>
